import React from "react";

import './Name.css';

function Name() {
    return (
        <div className="name">
            <p>Alaqmar<br></br>Gandhi</p>
            <img src={require('../Images/Arrow.png')} />
        </div>
    )
}

export default Name